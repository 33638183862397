<template>
  <b-modal
    v-if="meeting"
    :active.sync="isActive"
    :width="600"
    scroll="keep"
    has-modal-card
    trap-focus
    aria-role="dialog"
    aria-modal
    :on-cancel="closeModal"
  >
    <div class="modal-card is-width-auto">
      <section class="modal-card-body">
        <div>
          <div class="is-flex is-flex-space-between">
            <div class="is-flex is-flex-align-center">
              <div class="label is-size-4">Configurar sala</div>
            </div>
            <div>
              <b-button type="is-text" icon-right="times" @click="closeModal">Fechar</b-button>
            </div>
          </div>

          <div v-if="!withJanus" class="has-margin-top-medium">
            <h3>Condições de entrada na sala</h3>
            <div class="has-padding has-margin-top-small">
              <div class="is-flex is-flex-align-center">
                <b-checkbox v-model="roles.can_join" :disabled="!canEdit" @input="togglePermissions('can_join')">
                  <faicon
                    style="width: 25px"
                    icon="user-check"
                    size="lg"
                    :class="{ 'has-text-primary': roles.can_join }"
                    class="has-margin-right-small"
                  ></faicon>
                  Autorizar automaticamente a entrada de participantes
                </b-checkbox>
                <b-button
                  v-if="canEdit"
                  size="is-small"
                  type="is-text"
                  aria-label="Mais info"
                  icon-left="question-circle"
                  @click="infoBoxes.autoAccept = !infoBoxes.autoAccept"
                ></b-button>
              </div>
              <div v-if="infoBoxes.autoAccept" class="has-text-small has-text-muted">
                Para facilitar a gestão de entrada de participantes na sala, poderá autorizar automaticamente a entrada
                de todos, sem restrição e durante todo o período da sessão.
              </div>
            </div>
            <div v-if="isClassEdition && canEdit">
              <hr />
              <div>
                <b-checkbox v-model="keepHiddenNext" size="is-small">
                  Não voltar a apresentar este painel na abertura desta sala.
                </b-checkbox>
              </div>
            </div>
          </div>
          <div v-else class="has-margin-top-medium">
            <h3>Modo e permissões</h3>
            <div class="has-margin-top">
              <div class="has-margin-bottom-small label">Escolha o modo da {{ isClassEdition ? 'aula' : 'sala' }}:</div>
              <b-dropdown aria-role="list" :disabled="!canEdit">
                <button slot="trigger" class="button is-medium is-primary">
                  <span>{{ !roles.show_to_everyone ? 'Webinar' : 'Vídeo-conferência' }}</span>
                  <b-icon icon="chevron-down" size="is-small"></b-icon>
                </button>

                <b-dropdown-item v-if="roles.show_to_everyone" aria-role="listitem" @click="$emit('set-mode-webinar')"
                  >Webinar</b-dropdown-item
                >
                <b-dropdown-item v-else aria-role="listitem" @click="$emit('set-mode-meeting')"
                  >Vídeo-conferência</b-dropdown-item
                >
              </b-dropdown>
            </div>
            <div class="has-margin-top">Neste modo, os participantes podem:</div>
            <div class="has-margin-top">
              <div v-if="!roles.show_to_everyone">
                <div class="has-padding-small has-margin-left">
                  <b-checkbox
                    v-model="roles.audio_allowed"
                    :disabled="!canEdit || roles.show_to_everyone"
                    @input="togglePermissions('audio_allowed')"
                  >
                    <faicon
                      style="width: 25px"
                      icon="microphone"
                      size="lg"
                      :class="{ 'has-text-primary': roles.audio_allowed }"
                      class="has-margin-right-small"
                    ></faicon>
                    Ligar o microfone
                    <small class="has-text-tiny has-text-muted"
                      >(clique para {{ !roles.audio_allowed ? 'permitir' : 'desativar' }})</small
                    >
                  </b-checkbox>
                </div>
                <div class="has-padding-small has-margin-left">
                  <b-checkbox
                    v-model="roles.camera_allowed"
                    :disabled="!canEdit || roles.show_to_everyone"
                    @input="togglePermissions('camera_allowed')"
                  >
                    <faicon
                      style="width: 25px"
                      icon="video"
                      size="lg"
                      :class="{ 'has-text-primary': roles.camera_allowed }"
                      class="has-margin-right-small"
                    ></faicon>
                    Ligar a câmara
                    <small class="has-text-tiny has-text-muted"
                      >(clique para {{ !roles.camera_allowed ? 'permitir' : 'desativar' }})</small
                    >
                  </b-checkbox>
                </div>
              </div>
              <div v-else>
                <div class="has-padding-small has-margin-left-small is-flex is-flex-align-center">
                  <faicon
                    style="width: 25px"
                    :class="{
                      'has-text-dark': !roles.audio_allowed,
                      'has-text-primary': roles.audio_allowed
                    }"
                    class="is-size-3 has-margin-right-small"
                    :icon="roles.audio_allowed ? 'check' : 'times'"
                  ></faicon>
                  <faicon
                    style="width: 25px"
                    icon="microphone"
                    size="lg"
                    :class="{ 'has-text-primary': roles.audio_allowed }"
                    class="has-margin-right-small"
                  ></faicon>
                  Ligar o microfone
                </div>
                <div class="has-padding-small has-margin-left-small is-flex is-flex-align-center">
                  <faicon
                    style="width: 25px"
                    :class="{
                      'has-text-dark': !roles.camera_allowed,
                      'has-text-primary': roles.camera_allowed
                    }"
                    class="is-size-3 has-margin-right-small"
                    :icon="roles.camera_allowed ? 'check' : 'times'"
                  ></faicon>
                  <faicon
                    style="width: 25px"
                    icon="video"
                    size="lg"
                    :class="{ 'has-text-primary': roles.camera_allowed }"
                    class="has-margin-right-small"
                  ></faicon>
                  Ligar a câmara
                </div>
              </div>
              <div>
                <div class="has-padding-small has-margin-left-small is-flex is-flex-align-center">
                  <faicon
                    style="width: 25px"
                    class="is-size-3 has-margin-right-small has-text-primary"
                    icon="check"
                  ></faicon>
                  <faicon
                    style="width: 25px"
                    icon="users"
                    size="lg"
                    class="has-margin-right-small has-text-primary"
                  ></faicon>
                  Ouvir os outros participantes
                </div>
                <div class="has-padding-small has-margin-left-small is-flex is-flex-align-center">
                  <faicon
                    style="width: 25px"
                    :class="{
                      'has-text-dark': !roles.show_to_everyone,
                      'has-text-primary': roles.show_to_everyone
                    }"
                    class="is-size-3 has-margin-right-small"
                    :icon="roles.show_to_everyone ? 'check' : 'times'"
                  ></faicon>
                  <faicon
                    style="width: 25px"
                    icon="users"
                    size="lg"
                    :class="{ 'has-text-primary': roles.show_to_everyone }"
                    class="has-margin-right-small"
                  ></faicon>
                  Ver os outros participantes
                </div>
                <div class="has-padding-small has-margin-left-small is-flex is-flex-align-center">
                  <faicon
                    style="width: 25px"
                    :class="{
                      'has-text-dark': !roles.sharescreen_allowed,
                      'has-text-primary': roles.sharescreen_allowed
                    }"
                    class="is-size-3 has-margin-right-small"
                    :icon="roles.sharescreen_allowed ? 'check' : 'times'"
                  ></faicon>
                  <faicon
                    style="width: 25px"
                    icon="desktop"
                    size="lg"
                    :class="{ 'has-text-primary': roles.sharescreen_allowed }"
                    class="has-margin-right-small"
                  ></faicon>
                  Partilhar o ecrã
                </div>
              </div>
              <div v-if="!roles.show_to_everyone" class="has-margin-small has-text-small has-text-muted">
                Em modo <span>Webinar</span> os participantes só vêem os oradores na sessão mas qualquer participante é
                ouvido por todos.
              </div>
              <h4 v-if="false" class="has-margin-top-medium">Outras permissões</h4>
              <div v-if="false" class="has-padding has-margin-top-small">
                <div>
                  <b-checkbox
                    v-model="roles.plugin_allowed"
                    :disabled="!canEdit"
                    @input="togglePermissions('plugin_allowed')"
                  >
                    <faicon
                      style="width: 25px"
                      icon="plug"
                      size="lg"
                      :class="{ 'has-text-primary': roles.plugin_allowed }"
                      class="has-margin-right-small"
                    ></faicon>
                    Gerar códigos UC Plugin
                  </b-checkbox>
                </div>
              </div>
              <div>
                <hr class="has-margin-top-small" />
                <h3>Condições de entrada na sala</h3>
                <div class="has-padding has-margin-top-small">
                  <div class="is-flex is-flex-align-center">
                    <b-checkbox v-model="roles.can_join" :disabled="!canEdit" @input="togglePermissions('can_join')">
                      <faicon
                        style="width: 25px"
                        icon="user-check"
                        size="lg"
                        :class="{ 'has-text-primary': roles.can_join }"
                        class="has-margin-right-small"
                      ></faicon>
                      Autorizar automaticamente a entrada de participantes
                    </b-checkbox>
                    <b-button
                      v-if="canEdit"
                      size="is-small"
                      type="is-text"
                      aria-label="Mais info"
                      icon-left="question-circle"
                      @click="infoBoxes.autoAccept = !infoBoxes.autoAccept"
                    ></b-button>
                  </div>
                  <div v-if="infoBoxes.autoAccept" class="has-text-small has-text-muted">
                    Para facilitar a gestão de entrada de participantes na sala, poderá autorizar automaticamente a
                    entrada de todos, sem restrição e durante todo o período da sessão.
                  </div>
                  <div v-if="false && canEdit" class="has-text-small has-text-muted">
                    Os utilizadores convidados (utilizadores sem conta) não são aceites automaticamente.
                  </div>
                </div>
              </div>
              <div v-if="canEdit && meeting.anonymous">
                <hr class="has-margin-top-small" />
                <h3>Convidados</h3>
                <div class="has-padding has-margin-top-small">
                  <div class="is-flex is-flex-align-center">
                    <b-checkbox v-model="allowAnonymous" @input="toggleAnonymous">
                      Permitir convidados (utilizadores sem conta)
                    </b-checkbox>
                  </div>
                  <div v-if="allowAnonymous" class="is-flex is-flex-align-center has-margin-top">
                    <b-checkbox v-model="allowAnonymousWithoutCode" @input="toggleAnonymousWithoutCode">
                      Permitir acesso sem código de segurança
                    </b-checkbox>
                  </div>
                  <div v-if="allowAnonymous && !allowAnonymousWithoutCode">
                    <h4 class="has-margin-top-medium is-flex is-flex-align-center">
                      Segurança
                      <b-button
                        size="is-small"
                        type="is-text"
                        aria-label="Mais info"
                        class="has-margin-left-small"
                        icon-left="question-circle"
                        @click="infoBoxes.anonymousSecurity = !infoBoxes.anonymousSecurity"
                      ></b-button>
                    </h4>
                    <div v-if="infoBoxes.anonymousSecurity" class="has-text-small has-text-muted has-margin-bottom">
                      O código de segurança (opcional) evita que qualquer pessoa peça para entrar na sala. O código pode
                      ser alterado a qualquer momento, através deste painel. Com ou sem código de segurança, os
                      convidados só entram na sala após aceitação.
                    </div>
                    <div class="is-flex is-flex-align-center">
                      <b-field
                        label="Código de segurança"
                        :type="{ 'is-danger': !anonymousCode || anonymousCodeError }"
                        :message="!anonymousCode ? $t('message.form.required') : anonymousCodeError"
                      >
                        <b-input v-model="anonymousCode" custom-class="is-family-code" expanded></b-input>
                      </b-field>
                      <b-field>
                        <b-button
                          v-if="meeting.anonymous.code == anonymousCode"
                          type="is-light is-small"
                          class="has-margin-top has-margin-left has-height-full"
                          @click="toClipboard(anonymousCode)"
                          >Copiar</b-button
                        >
                        <b-button
                          v-if="meeting.anonymous.code == anonymousCode"
                          type="is-light is-small"
                          class="has-margin-top has-margin-left"
                          icon-left="sync-alt"
                          @click="newAnonymousCode"
                          >Gerar novo</b-button
                        >
                        <b-button
                          v-if="!loading && meeting.anonymous.code != anonymousCode"
                          type="is-light is-small"
                          class="has-margin-top has-margin-left"
                          label="Guardar"
                          @click="saveAnonymousCode"
                        ></b-button>
                      </b-field>
                    </div>
                  </div>
                  <div v-if="allowAnonymous" class="has-margin-top">
                    <h4 class="is-flex is-flex-align-center">
                      Partilhar
                      <b-button
                        size="is-small"
                        type="is-text"
                        class="has-margin-left-small"
                        aria-label="Mais info"
                        icon-left="question-circle"
                        @click="infoBoxes.anonymousShare = !infoBoxes.anonymousShare"
                      ></b-button>
                    </h4>
                    <div v-if="infoBoxes.anonymousShare" class="has-text-small has-text-muted has-margin-bottom">
                      Se estiver a utilizar a UC Teacher ou UC Student, o endereço a partilhar aos convidados
                      (utilizadores sem conta) deve ser o endereço da plataforma UC Meetings. Não se esqueça que a sua
                      sala (ou aula virtual) é a mesma em todas as plataformas. Além do endereço, o acesso à sala pode
                      ser feito através da página de autenticação da plataforma UC Meetings (carregar no botão "Entrar
                      como convidado").
                    </div>
                    <div class="label is-marginless">
                      Link de acesso à sala
                      <span v-if="anonymousCode" class="has-text-tiny has-text-muted">(com código de segurança)</span>
                    </div>
                    <div class="is-flex is-flex-align-center">
                      <a
                        target="_blank"
                        :href="anonymousUrl"
                        class="has-margin-right-small has-text-small is-family-code"
                      >
                        <v-clamp autoresize :max-lines="2">{{ anonymousUrl }}</v-clamp>
                      </a>
                      <b-button type="is-light is-small" @click="toClipboard(anonymousUrl)">Copiar</b-button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="canEdit && isClassEdition && !roles.show_to_everyone">
                <hr class="has-margin-top-small" />
                <div>
                  <div>
                    <b-button
                      size="is-small"
                      type="is-text"
                      :icon-right="!infoBoxes.additionalInfo ? 'chevron-down' : 'chevron-up'"
                      @click="infoBoxes.additionalInfo = !infoBoxes.additionalInfo"
                      >Notas de ajuda</b-button
                    >
                  </div>
                  <div v-if="infoBoxes.additionalInfo" class="has-padding has-background-light">
                    <h4>Modo de aula</h4>
                    <div v-if="!roles.show_to_everyone" class="has-text-small has-margin-top has-text-muted">
                      Em sessões com muitos participantes e em aulas de teor mais teórico, o modo WEBINAR é o mais
                      indicado. Se precisar dar a palavra a um participante, promova-o a <strong>Orador</strong> (ver
                      notas de ajuda, ao fundo), para que todos os restantes participantes o possam ver e ouvir. Os
                      oradores podem também partilhar o ecrã.
                    </div>
                    <div v-else class="has-margin-top has-text-small has-text-muted">
                      O modo vídeo-conferência é o mais semelhante a plataformas como o Zoom. Todos os participantes são
                      oradores.
                    </div>
                    <h4 class="has-margin-top-medium">Promover a Orador</h4>
                    <div class="has-margin-top-small has-text-small">
                      Um participante promovido a Orador tem todas as permissões ativas, exceto "Gerar códigos UC
                      Plugin" (precisa ser definida individualmente). Um orador vê e ouve apenas os outros oradores.
                    </div>
                    <h4 class="has-margin-top">Como promover</h4>
                    <div class="content">
                      <ul class="has-text-small">
                        <li>
                          <faicon icon="hand-paper" size="lg" class="has-margin-right"></faicon>Peça ao participante
                          para pedir a palavra: na caixa de identificação do participante com a mão levantada, irá
                          encontrar um botão para promover rapidamente o participantes a Orador.
                        </li>
                        <li>
                          Procure a miniatura do participante, abra o menu de opções e clique em "Promover a Orador".
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="isClassEdition" class="has-margin-top-medium">
                <b-checkbox v-model="keepHiddenNext" size="is-small">
                  Não voltar a apresentar este painel na abertura desta sala.
                </b-checkbox>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </b-modal>
</template>

<script>
import FwConfig from '@/fw-modules/fw-core-vue/config'
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

const ANONYMOUS_CODE_SIZE = 8
const ALLOWED_ROLES = [
  'audio_allowed',
  'camera_allowed',
  'sharescreen_allowed',
  'plugin_allowed',
  'show_to_everyone',
  'can_join'
]

export default {
  props: {
    show: Boolean,
    meeting: Object,
    instance: Object,
    attendees: Object,
    visibility: String,
    isClassEdition: Boolean,
    withJanus: Boolean
  },

  data() {
    const roles = {}
    this.setRoles(this.instance, roles)

    let allowAnonymous
    let allowAnonymousWithoutCode
    let anonymousCode
    if (this.meeting && this.meeting.anonymous) {
      allowAnonymous = this.meeting.anonymous.allowed
      anonymousCode = this.meeting.anonymous.code
      allowAnonymousWithoutCode = !anonymousCode
    }

    return {
      isActive: this.show || false,
      loading: false,
      roles: roles,
      allowAnonymous: allowAnonymous,
      allowAnonymousWithoutCode: allowAnonymousWithoutCode,
      anonymousCode: anonymousCode,
      anonymousCodeError: null,
      keepHiddenNext: Boolean(this.visibility == 'hidden'),
      infoBoxes: {
        additionalInfo: false,
        autoAccept: false,
        anonymousSecurity: false,
        anonymousShare: false
      }
    }
  },

  computed: {
    loggedUser() {
      return this.$store.getters.getUser
    },
    canEdit() {
      return ServiceMeetings.withRole(this.meeting.roles, 'can_promote')
    },
    anonymousUrl() {
      const url = `${FwConfig.apps.ucmeetingsUrl}/live/${this.meeting.key_alias}`
      if (!this.loading && this.allowAnonymous && !this.allowAnonymousWithoutCode) {
        return `${url}/${encodeURIComponent(this.meeting.anonymous.code)}`
      } else {
        return url
      }
    }
  },

  watch: {
    show(value) {
      this.isActive = value
    },
    keepHiddenNext(hidden) {
      this.$emit('update-visibility-modal-bulk-permissions', hidden)
    },
    instance: {
      handler: function(instance) {
        this.setRoles(instance, this.roles)
      },
      deep: true
    }
  },

  methods: {
    setMode(value) {
      if (value == 'webinar') this.$emit('set-mode-webinar')
      else this.$emit('set-mode-meeting')
    },
    setRoles(instance, roles) {
      for (let key of ALLOWED_ROLES) {
        roles[key] = instance.withRole(key)
      }
    },
    togglePermissions(name) {
      const roles = {}
      roles[ServiceMeetings.getRole(name)] = this.roles[name]
      this.$emit('update-roles', roles)
    },

    async updateAnonymous() {
      this.loading = true
      let meeting
      let code = this.allowAnonymous && !this.allowAnonymousWithoutCode ? this.anonymousCode : null

      try {
        meeting = await ServiceMeetings.updateMeetingAnonymous(this.meeting.key, this.allowAnonymous, code)
      } catch (error) {
        this.anonymousCodeError = utils.errors(error).getTranslated()
        if (!this.anonymousCodeError) {
          throw error
        } else {
          return
        }
      } finally {
        this.loading = false
      }

      console.info(`Anonymous config updated allowed:${this.allowAnonymous} code:${this.anonymousCode}`)
      this.anonymousCodeError = null
      this.meeting.anonymous = meeting.anonymous
    },
    toggleAnonymous() {
      if (this.allowAnonymous) {
        this.allowAnonymousWithoutCode = false
        this.anonymousCode = this.meeting.anonymous.code || utils.randomLowerString(ANONYMOUS_CODE_SIZE)
      }
      this.updateAnonymous()
    },
    toggleAnonymousWithoutCode() {
      if (!this.allowAnonymousWithoutCode) {
        this.anonymousCode = this.meeting.anonymous.code || utils.randomLowerString(ANONYMOUS_CODE_SIZE)
      }
      this.updateAnonymous()
    },
    newAnonymousCode() {
      this.allowAnonymousWithoutCode = false
      this.anonymousCode = utils.randomLowerString(ANONYMOUS_CODE_SIZE)
      this.updateAnonymous()
    },
    saveAnonymousCode() {
      if (this.anonymousCode && this.meeting.anonymous.code != this.anonymousCode) {
        this.updateAnonymous()
      }
    },
    toClipboard(url) {
      this.$copyText(url).then(() => {
        this.$buefy.toast.open({
          duration: 3000,
          message: `Endereço copiado`,
          position: 'is-top'
        })
      })
    },

    closeModal() {
      this.isActive = false
      this.$emit('close', false)
    }
  }
}
</script>
